import classnames from 'classnames';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import Tile from 'app/components/partials/tile';
import React, { Component } from 'react';

const ROOMS_LIMIT = 6;

class OtherRoomsInVenue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roomsInVenues: [],
            venues: [],
            expandAll: false
        };
    }


    // Lifecycle
    componentDidMount() {
        const { external_venue_id: externalVenueId } = this.props.selectedRoom;

        this.fetchRoomsIfNecessary(externalVenueId);
        this.fetchVenueIfNecessary(externalVenueId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedRoom.external_venue_id !== nextProps.selectedRoom.external_venue_id) {
            const nextExternalVenueId = nextProps.selectedRoom.external_venue_id;
            this.fetchRoomsIfNecessary(nextExternalVenueId);
            this.fetchVenueIfNecessary(nextExternalVenueId);
        }

        if (this.props.selectedRoom.slug !== nextProps.selectedRoom.slug) {
            this.setState({
                expandAll: false
            });
        }
    }

    fetchRoomsIfNecessary(externalVenueId) {
        if (this.getRoomsInVenue(externalVenueId)) {
            return;
        }

        fetch(ENDPOINTS.ROOMS_BY_EXTERNAL_VENUE_ID(externalVenueId))
            .then((response) => {
                return response && response.data && response.data.length && response.data || [];
            })
            .catch(() => {
                return [];
            })
            .then((rooms) => {
                this.setState((state) => ({
                    ...state,
                    roomsInVenues: [
                        {
                            rooms,
                            externalVenueId
                        },
                        ...state.roomsInVenues
                    ]
                }));
            });
    }

    getRoomsInVenue(externalVenueId) {
        return this.state.roomsInVenues.find((roomsInVenue) => roomsInVenue.externalVenueId === externalVenueId);
    }

    fetchVenueIfNecessary(externalVenueId) {
        if (this.getVenue(externalVenueId)) {
            return;
        }

        fetch(ENDPOINTS.VENUES_BY_EXTERNAL_VENUE_ID(externalVenueId))
            .then((response) => {
                return response && response.data && response.data.length && response.data[0] || [];
            })
            .catch(() => {
                return null;
            })
            .then((venue) => {
                if (venue) {
                    this.setState((state) => ({
                        ...state,
                        venues: [
                            venue,
                            ...state.venues
                        ]
                    }));
                }
            });
    }

    getVenue(externalVenueId) {
        return this.state.venues.find((venue) => venue.attributes.external_venue_id === externalVenueId);
    }


    // Renders
    renderRoom(data, index) {
        const {
            capacity_max: capacityMax,
            capacity_min: capacityMin,
            name: title,
            short_description: description,
        } = data.attributes;

        const roomDetails = {
            ...data.attributes,
            description,
            title,
            capacity: { min: capacityMin, max: capacityMax }
        };

        return (
            <Tile
                key={index}
                item={{
                    type: 'room',
                    data: data.attributes
                }}
                {...roomDetails}
            />
        );
    }

    renderRoomsInVenue() {
        const { expandAll } = this.state;
        const { external_venue_id: externalVenueId, slug } = this.props.selectedRoom;
        const roomsInVenue = this.getRoomsInVenue(externalVenueId);

        if (roomsInVenue) {
            const rooms = roomsInVenue.rooms.filter((data) => data.attributes.is_published && data.attributes.slug !== slug);

            if (rooms.length) {
                const isExpandable = rooms.length > ROOMS_LIMIT;
                const roomsTruncatedIfNeccessary = isExpandable && !expandAll ? rooms.slice(0, ROOMS_LIMIT) : rooms;

                return (
                    <div>
                        <div className="tile-block-inner">
                            {roomsTruncatedIfNeccessary.map((room, index) => this.renderRoom(room, index))}
                        </div>
                        {isExpandable && (
                            <button
                                type="button"
                                onClick={() => this.setState({ expandAll: !expandAll })}
                                className="other-rooms-in-venue-button || button primary"
                            >
                                <Icon name="plus-capacity" />
                                {expandAll ? 'Show less' : 'Show more'}
                            </button>
                        )}
                    </div>
                );
            }

            return (
                <div className="rooms-filter-empty">
                    <p className="heading-3">
                        Nothing to show here.
                    </p>
                </div>
            );
        }

        return (
            <Loader type="block primary || rooms-filter-empty" />
        );
    }

    renderTitle() {
        const { external_venue_id: externalVenueId } = this.props.selectedRoom;
        const venue = this.getVenue(externalVenueId);

        if (venue) {
            return `Other rooms in ${venue.attributes.name}`;
        }

        return 'Other rooms';
    }

    render() {
        const { expandAll } = this.state;
        const className = classnames('tile-block || block-section || other-rooms-in-venue', { 'is-expanded': expandAll });

        return (
            <section className={className}>
                <header className="section-header || rooms-filter || constrain-width">
                    <h2 className="heading-1">{this.renderTitle()}</h2>
                </header>
                <div className="constrain-width">
                    {this.renderRoomsInVenue()}
                </div>
            </section>
        );
    }
}

OtherRoomsInVenue.propTypes = {
    selectedRoom: PropTypes.object.isRequired
};

export default OtherRoomsInVenue;
